import { SimpleGrid, Box, Text } from "@chakra-ui/react";
import { SimpleBook } from "oe-shared/models";
import BookCard from "components/chakra-ui/BookCard";

type GridSectionProps = {
  gridBooks: SimpleBook[];
  title: string;
};

const GridSection = ({ gridBooks, title }: GridSectionProps) => {
  if (!gridBooks.length) return null;

  return (
    <Box as="section" m={{ base: 4, lg: 14 }} mt={{ base: 4, lg: 6 }}>
      <Text
        as="h2"
        textStyle="headers.secondary"
        fontWeight="black"
        color="ui.magenta.400"
        maxW="60rem"
        mb={{ base: 4 }}
      >
        {title}
      </Text>
      <SimpleGrid
        as="ul"
        minChildWidth={{
          base: "8.5rem",
          md: "10rem",
        }}
        spacing={{
          base: 4,
          md: 6,
        }}
      >
        {gridBooks.map((book) => (
          <BookCard book={book} key={book.url} listStyleType="none" />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default GridSection;

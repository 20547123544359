import { ListItem, OrderedList, Text, Box } from "@chakra-ui/react";

interface HowItWorksItemProps {
  itemPosition: string;
}

export const HowItWorks = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <Box
      maxWidth="34rem"
      borderColor="ui.teal.100"
      borderRadius={50}
      borderWidth={6}
    >
      {children}
    </Box>
  );
};

export const HowItWorksTitle = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return (
    <Text as="h2" textStyle="headers.tertiary" margin="4">
      {children}
    </Text>
  );
};

export const HowItWorksList = ({
  children,
}: React.PropsWithChildren<unknown>) => (
  <OrderedList marginInlineStart={0}>{children}</OrderedList>
);

export const NumberedCircle = ({
  children,
  ...props
}: React.ComponentProps<typeof Box>) => {
  return (
    <Box
      bgColor="ui.teal.400"
      color="ui.white"
      textAlign="center"
      borderRadius="50%"
      verticalAlign="middle"
      fontWeight="medium"
      as="span"
      marginRight={[2, 6]}
      height={10}
      minWidth={10}
      lineHeight={10}
      {...props}
    >
      {children}
    </Box>
  );
};

export const HowItWorksItem = ({
  children,
  itemPosition,
}: React.PropsWithChildren<HowItWorksItemProps>) => {
  return (
    <ListItem
      display="flex"
      alignItems="center"
      padding="4"
      _odd={{ bgColor: "ui.gray.xlight" }}
    >
      <NumberedCircle>{itemPosition}</NumberedCircle>
      {children}
    </ListItem>
  );
};

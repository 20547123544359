import { Box } from "@chakra-ui/react";
import Carousel from "components/chakra-ui/Carousel";
import { GetStaticProps } from "next";
import {
  CollectionBook,
  fetchCuratedCollections,
} from "components/chakra-ui/fetching";
import SectionDivider from "components/chakra-ui/SectionDivider";
import HowItWorks from "components/chakra-ui/HomePage/HowItWorks";
import GridSection from "components/chakra-ui/HomePage/GridSection";
import ReadAnywhere from "components/chakra-ui/HomePage/ReadAnywhere";
import TakeAPeak from "components/chakra-ui/HomePage/TakeAPeak";
import HeroImage from "components/chakra-ui/HomePage/HeroImage";
import catchGetStaticPropsErrors from "utils/catchGetStaticPropsErrors";
import { SiteSectionLabel } from "oe-shared/analytics";
import { PageView } from "components/chakra-ui/PageView";
import { APP_VERSION, RELEASE_STAGE } from "utils/env";
import { pageName } from "analytics/adobe";
import LayoutPage from "components/chakra-ui/LayoutPage";

type CollectionListProps = {
  highSchoolCollection: CollectionBook;
  middleSchoolCollection: CollectionBook;
  earlyGradesCollection: CollectionBook;
  gridCollection: CollectionBook;
};

const HomePage = ({
  highSchoolCollection,
  middleSchoolCollection,
  earlyGradesCollection,
  gridCollection,
}: CollectionListProps) => {
  // We keep this here to make debugging releases and rollbacks easier
  console.log("App version: ", APP_VERSION);
  console.log("Release stage: ", RELEASE_STAGE);

  return (
    <LayoutPage>
      <PageView
        name={pageName.home}
        category={SiteSectionLabel.MARKETING_SITE}
      />
      <Box>
        <HeroImage />
        <SectionDivider />
        <HowItWorks />
        <SectionDivider />
        <TakeAPeak />
        <SectionDivider />

        {/* Grid Section */}
        <GridSection
          gridBooks={gridCollection.books}
          title={gridCollection.title}
        />
        <SectionDivider />

        {/* Carousels */}
        <Carousel
          books={highSchoolCollection.books}
          colorScheme="purple"
          title={highSchoolCollection.title}
          description="Find new stories that will transport you to fantastic places, or dive deeper into stories close to home that you won’t forget."
        />
        <SectionDivider />
        <Carousel
          books={middleSchoolCollection.books}
          colorScheme="magenta"
          title={middleSchoolCollection.title}
          description="Discover new worlds, find your favorite stories, or learn about real people and events."
        />
        <SectionDivider />
        <Carousel
          books={earlyGradesCollection.books}
          colorScheme="teal"
          title={earlyGradesCollection.title}
          description="Read on your own or with your grownup. Animals, fairies, mysteries, action and adventure and even chapter books are always with you in this collection."
        />
        <SectionDivider />
        <ReadAnywhere />
        <SectionDivider />
      </Box>
    </LayoutPage>
  );
};

export default HomePage;

export const getStaticProps: GetStaticProps = async () => {
  try {
    const [
      gridCollection,
      highSchoolCollection,
      middleSchoolCollection,
      earlyGradesCollection,
    ] = await fetchCuratedCollections();

    return {
      props: {
        highSchoolCollection,
        middleSchoolCollection,
        earlyGradesCollection,
        gridCollection,
      },
      revalidate: 60 * 10, // 10 minute
    };
  } catch (error) {
    return catchGetStaticPropsErrors(error as Error);
  }
};
